@font-face {
  font-family: 'ico-feeder';
  src:  url('fonts/ico-feeder.eot?3or3ha');
  src:  url('fonts/ico-feeder.eot?3or3ha#iefix') format('embedded-opentype'),
    url('fonts/ico-feeder.ttf?3or3ha') format('truetype'),
    url('fonts/ico-feeder.woff?3or3ha') format('woff'),
    url('fonts/ico-feeder.svg?3or3ha#ico-feeder') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ico-feeder' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-isologo-white .path1:before {
  content: "\e954";
  color: rgb(255, 255, 255);
}
.icon-isologo-white .path2:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5947;
}
.icon-isologo-white .path3:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-isologo-metrics-white .path1:before {
  content: "\e957";
  color: rgb(255, 255, 255);
}
.icon-isologo-metrics-white .path2:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.6177;
}
.icon-isologo-metrics-white .path3:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-isologo-metrics-white .path4:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5947;
}
.icon-logo-color:before {
  content: "\e95b";
  color: #fd3f6c;
}
.icon-logo-metrics-color .path1:before {
  content: "\e95c";
  color: rgb(253, 62, 107);
}
.icon-logo-metrics-color .path2:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(253, 62, 107);
  opacity: 0.6177;
}
.icon-logo-metrics-white .path1:before {
  content: "\e95e";
  color: rgb(255, 255, 255);
}
.icon-logo-metrics-white .path2:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.6177;
}
