.app-container {
    display: flex;
}

.app-container__sidebar {
    width: 10rem;
    position: relative;
}

.app-container__main {
    /* Take the remaining width */
    flex: 1;

    /* Make it scrollable */
    overflow: auto;
}
    