:root {
  --color-primary: 253, 62, 107;
  --color-secondary: 58, 87, 187;
  --color-tertiary: 43, 51, 87;
  --color-success: 85, 136, 124;
  --color-warning: 255, 174, 3;
  --color-danger: 236, 35, 42;

  --color-light-blue: 79, 182, 240;
  --color-dark-blue-grey: 27, 39, 81;
  --color-dark-blue-grey-2: 23, 33, 69;
  --color-dark-grey-blue: 51 48 106;
  --color-dark-grey-blue-2: 43, 51, 87;
  --color-dark-grey-blue-3: 19, 27, 58;
  --color-midnight-blue: 8, 10, 52;
  --color-mainBgColor: 255, 255, 255;
  --color-dark-purple: 87, 47, 140;
  --color-lighter-purple: 149, 93, 241;
  --color-dusk: 84, 93, 124;
  --color-dusk-blue: 50, 75, 167;
  --color-cornflower-blue: 70, 103, 219;
  --color-battleship-grey: 107, 108, 133;
  --color-dark-sky-blue: 79, 182, 240;
  --color-blueberry: 87, 47, 140;
  --color-dark-blue: 27, 39, 81;
  --color-ultra-dark-blue: 44, 46, 65;
  --color-light-gray-blue: 238, 238, 241;
  --color-bright-cyan: 70, 207, 255;
  --color-powder-blue: 176, 192, 252;
  --color-dark-blue-grey-3: 15, 22, 47;
  --color-dark-sea-green: 17, 145, 113;
  --color-pinkish-grey: 209, 209, 209;

  --color-objective-attention: 35, 204, 255;
  --color-objective-validation: 253, 62, 107;
  --color-objective-engagement: 255, 173, 94;
  --color-objective-charisma: 222, 121, 215;
  --color-objective-surprise: 90, 213, 187;
  --color-objective-inspire: 85, 122, 255;
  --color-objective-intensity: 167, 90, 246;
  --color-objective-reject: 255, 134, 110;
  --color-objective-horror: 175, 198, 104;
  --color-objective-upset: 253, 121, 159;
  --color-objective-scared: 135, 140, 233;

  --color-green-ECR: 6, 184, 105;

  --color-red-campaign: 255, 52, 51;
  --color-red-campaign-secondary: 252, 147, 147;
  --color-orange-campaign: 255, 126, 47;
  --color-orange-campaign-secondary: 252, 186, 145;
  --color-yellow-campaign: 255, 204, 62;
  --color-yellow-campaign-secondary: 252, 226, 153;
  --color-green-campaign: 211, 255, 51;
  --color-green-campaign-secondary: 230, 252, 147;
  --color-turquoise-campaign: 57, 255, 192;
  --color-turquoise-campaign-secondary: 150, 252, 220 ;
  --color-blue-campaign: 55, 188, 255;
  --color-blue-campaign-secondary: 149, 218, 252;
  --color-purple-campaign: 217, 120, 255;
  --color-purple-campaign-secondary: 235, 190, 252;
  --color-pink-campaign: 253, 62, 107;
  --color-pink-campaign-secondary: 251, 151, 174;
  --color-green-corporate: 92, 205, 185;
  --color-ECR-A: 0, 179, 168;
  --color-ECR-B: 0, 206, 135;
  --color-ECR-C: 146, 203, 78;
  --color-ECR-D: 239, 172, 63;
  --color-ECR--: 0, 206, 135;
}
html {
  scroll-behavior: smooth;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

/** Floating Labels */
.help-text {
  letter-spacing: -0pt;
  line-height: 15pt;
  font-size: 9pt;
  font-weight: 500;
  opacity: 0.6;
}

label, input {
  transition: all 0.2s;
  touch-action: manipulation;
}

input {
  cursor: text;
}

input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.text > ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.text, .text > * {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/**
* Show the placeholder when the input is focused.
*/
input:focus::-webkit-input-placeholder {
  opacity: 1;
}

input:not(:placeholder-shown) + label,
input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
}
/** -------------- **/

.no-overflow-x, .no-overflow-x > * {
  overflow-x: hidden !important;
}

.Toastify__toast-container {
  min-width: 26em;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-mainBgColor font-montserrat;
  }
  .floating-label input:not(:placeholder-shown) + label,
  .floating-label input:focus + label,
  .floating-label textarea:not(:placeholder-shown) + label,
  textarea:focus + label {
    @apply transform scale-75 -translate-y-4 z-0 ml-0 px-1 py-0 text-primary;
  }
  .fixed-floating-label input + label {
    @apply transform scale-75 -translate-y-4 z-0 ml-0 px-1 py-0 text-primary;
  }
  /* .floating-label input:focus-within ~ label,
  .floating-label input:not(placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-4 z-0 ml-0 px-1 py-0 text-primary;
  }
  .floating-label textarea:focus-within ~ label,
  .floating-label textarea:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-4 z-0 ml-0 px-1 py-0 text-primary;
  } */
  .help-text {
    @apply font-montserrat text-dark-grey-blue-2;
  }
  .Toastify__toast--success {
    @apply bg-dark-sea-green font-montserrat font-semibold text-lg;
  }
  .Toastify__toast--error {
    @apply bg-danger font-montserrat font-semibold text-lg;
  }

  .black-radio-checked {
    accent-color: black;
  }
}
