

.sideright-wrapper {
    z-index: 9999;
    position:absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    overflow-x: hidden;

    .react-reveal {
        height: 100%;
    }

    .component {
        opacity: 1 !important;
        background: #F4F1ED;
        box-shadow: 0px 6px 18px #212121;
        border-radius: 10px 0px 0px 10px;
    }

    .header {
        border-bottom: 1px solid #c5c4c3;
        h1 {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 32px;

            /* identical to box height, or 160% */
            letter-spacing: 2px;

            /* Design/Black 100 */
            color: #212121;
        }
    }

    .content {
       
    }

    .footer {
        border-top: 1px solid #b5b5b5;
        background-color: #f4f1ed !important;
    }
}